<template>
    <div>
        <div class="container header-fixed">
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="d-block pt-lg-5 pt-md-5 pt-3">
                        <h1>Perolehan Formulir</h1>
                    </div>
                    <!-- Search -->
                    <div class="d-flex align-items-center mb-0 d-flex-mobile">
                        <div class="input-group input-group-merge mb-md-0 mb-3 flex-grow-1">
                            <input v-model="keyword" type="search" class="form-control form-control-prepended search"
                                placeholder="Cari formulir disini..." v-on:keyup.enter="getData">
                            <div class="input-group-prepend">
                                <div class="input-group-text">
                                    <span class="fe fe-search"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Formulir List Front -->
                    <div class="section pb-5">
                        <div class="d-flex align-items-center justify-content-between mb-3 pb-3 border-bottom">
                            <h4 class="m-0">Menampilkan {{listData.length}} unit tersedia</h4>
                        </div>

                        <div v-if="fetching" class="row">
                            <div v-for="(item,index) in [1,2,3,4,5,6,7,8]" :key="index" class="col-md-3 mb-4 col-6 col-load">
                                <div class="card h-100 m-0">
                                    <div class="d-block p-3 h-100">
                                        <div class="d-flex flex-column justify-content-between h-100">
                                            <div class="flex-grow-1">
                                                <div class="card-img-news mb-3 add-skeleton-load delay-1"></div>
                                                <div class="skeleton-bar">
                                                    <div class="sb-lg mb-2"></div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="skeleton-bar">
                                                     <div class="sb-sm sb-thin mb-2"></div>
                                                    <div class="sb-lg sb-bold"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div v-if="!fetching">
                            <div v-if="listData.length == 0" class="empty-content" data-empty-title="Daftar Unit tidak tersedia">
                                <div class="row">
                                    <div v-for="(item,index) in [1,2,3,4]" :key="index" class="col-md-3 mb-4 col-6 col-load">
                                        <div class="card h-100 m-0">
                                            <div class="d-block p-3 h-100">
                                                <div class="d-flex flex-column justify-content-between h-100">
                                                    <div class="flex-grow-1">
                                                        <div class="card-img-news mb-3 add-skeleton-load skeleton-empty delay-1"></div>
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-lg mb-2"></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="skeleton-bar skeleton-empty">
                                                            <div class="sb-sm sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-bold"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="listData.length > 0" class="row">
                                <div v-for="(item, index) in listData" :key="index" class="col-md-3 col-6 mb-4">
                                    <div class="card lift h-100 m-0">
                                        <div class="d-block h-100">
                                            <div class="d-flex flex-column h-100">
                                                <div class="mb-4 flex-grow-1">
                                                    <div class="card-img-top"
                                                        v-bind:style="{ 'background-image': 'url(' + item.cover + ')' }">
                                                    </div>
                                                    <div class="d-block p-3">
                                                        <h3 class="mb-1 mt-0">{{item.title}}</h3>
                                                        <div class="text-muted lh-sm lh-sm">
                                                            {{item.description}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-block p-3">
                                                    <router-link :to="'/formulir/list/'+item.id" class="btn btn-primary-light btn-block">Lihat
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import {
        mapState
    } from 'vuex'

    export default {
        name: 'Beranda',
        components: {
            
        },
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - PPDB'
        },
        mounted() {
            this.$store.dispatch('loadUserData')
        },
        computed: mapState([
            'userdata'
        ]),
        data() {
            return {
                search: "",
                listData: [],
                pageOfItems: [],
                fetching: true,
                keyword : ''
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function () {
                this.listData = [];
                this.fetching = true;
                let uri = this.$apiconfig + 'website/formulir/unit';
                this.$http.get(uri,{
                    params : {
                        keyword : this.keyword
                    }
                }).then(res => {
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.listData = getResponse.data;
                    } else {
                        this.listData = [];
                    }
                    this.fetching = false;
                });
            },
        }
    }
</script>